.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  position: sticky;
  top: 0;
  right: 0;
  background-color: rgb(9, 24, 38);
  /* border-bottom: 1px solid white; */
}
.header > div:nth-of-type(1) {
  width: 5rem;
  flex: 50%;

  height: 5rem;
}
.header > div > img {
  width: 5rem;
  height: 5rem;
}
.header > div:nth-of-type(2) {
  margin-right: 1rem;
  display: flex;
  flex: 50%;
  justify-content: flex-end;
  gap: 2rem;
}
.issearch {
  width: 0;
  background-color: transparent;
  opacity: 0%;
  outline: none;
  border: none;
  color: white;
  font-size: 2rem;
  border-bottom: 1px solid white;
  transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.ishover {
  color: white;
  font-size: 2.5rem;
  transition: all 0.3s ease-in-out;
  opacity: 100%;
}
.ishover:hover {
  transform: rotate(90deg);
  opacity: 0%;
}

.display-search {
  width: 50%;
  opacity: 100%;
}
